<template lang="pug">
  .content
    SettingEditor(
      v-for="row in settingList"
      :form-data="row"
      :open-name="openName"
      :key="row.name"
    )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SettingEditor from '~v/setting/setting-editor'

export default {
  name: 'settings',
  data () {
    return {
      openName: ''
    }
  },
  components: {
    SettingEditor
  },
  watch: {
  },
  // 所有內容的多數 data 來自於 vuex 的 state 以 module 裡的 getter 的方式嵌入 computed 中
  computed: {
    // 嵌入 news 的 state 資料
    ...mapGetters('system', {
      settingList: 'getListData'
    }),

    // 嵌入 auth 的 state 資料
    ...mapGetters('auth', [
      'userInfo'
    ])
  },

  methods: {
    ...mapActions('system', { findSetting: 'find' })
  },
  async mounted () {
    this.findSetting()
  }
}

</script>

<style lang="sass">
</style>
