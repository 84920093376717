<template lang="pug">
  Row(style="margin-bottom: 8px;")
    Col(span="24")
      div(
        style="margin-bottom: 16px;"
      )
        .title(
          style="float: left;"
          @click="toggleSetting"
        )
          Icon.pointer(
            :type="menuIcon"
            size="28"
          )
          span {{ formData.name }}

        Button.actions(
          type="success"
          icon="md-cloud-upload"
          @click="onUpdate"
          style="float: right;"
          :disabled="!isDirty"
          v-if="isDirty"
          ) {{ $t("Save") }}

    transition(name="fade")
      Col(span="24" v-show="isOpen" style="margin: 16px 0;")
        Table.app-table(:columns="columns" :data="dataList" border)
          template(
            slot-scope="{ row, index }"
            slot="index"
          )
            span {{ index + 1 }}

          template(
            slot-scope="{ row, index }"
            slot="actions"
          )
            Button.actions(
              type="success"
              icon="md-arrow-round-up"
              size='small'
              @click="swapIndex(index, -1)"
              :disabled="index === 0"
              )
            Button.actions(
              type="success"
              icon="md-arrow-round-down"
              size='small'
              @click="swapIndex(index, 1)"
              :disabled="index === dataList.length - 1"
              )

            Button.actions(
              type="primary"
              icon="md-add"
              size='small'
              @click="addConfig(index)"
              )

            Button.actions(
              type="error"
              icon="md-close"
              size='small'
              @click="removeConfig(index)"
              )

        Button.actions(
          type="success"
          icon="md-cloud-upload"
          @click="onUpdate"
          style="float: right; margin-top: 8px;"
          v-if="isDirty"
          ) {{ $t("Save") }}

</template>
<script>
import to from 'await-to-js'
import { mapActions } from 'vuex'

export default {
  name: 'setting-editor',
  mixins: [],
  components: {
  },
  data () {
    return {
      dataList: [],
      timeoutId: null,
      isDirty: false,
      isOpen: false,
      columns: [
        {
          title: '#',
          slot: 'index',
          width: 95,
          align: 'center'
        },
        {
          title: this.$t('Config key'),
          width: 350,
          render: (h, params) => {
            return h('Input', {
              props: {
                type: 'text',
                value: params.row.key
              },
              on: {
                'on-change': event => {
                  this.updateRow(params.index, 'key', event.target.value)
                }
              }
            })
          }
        },
        {
          title: this.$t('Config value'),
          render: (h, params) => {
            return h('Input', {
              props: {
                type: 'text',
                value: params.row.value
              },
              on: {
                'on-change': event => {
                  this.updateRow(params.index, 'value', event.target.value)
                }
              }
            })
          }
        },
        {
          title: this.$t('Config description'),
          width: 350,
          render: (h, params) => {
            return h('Input', {
              props: {
                type: 'text',
                value: params.row.description
              },
              on: {
                'on-change': event => {
                  this.updateRow(params.index, 'description', event.target.value)
                }
              }
            })
          }
        },
        {
          title: this.$t('Actions'),
          slot: 'actions',
          width: 158
        }
      ]
    }
  },
  props: {
    formData: Object
  },
  watch: {
    formData: {
      handler (nv, ov) {
        if (!this.formData) return
        this.dataList = [ ...this.formData.data ]
      },
      deep: true
    }
  },
  computed: {
    menuIcon () {
      return this.isOpen ? 'ios-arrow-down' : 'ios-arrow-forward'
    }
  },
  mounted () {
    if (this.formData) this.dataList = this.formData.data
  },
  methods: {
    ...mapActions('system', { updateSetting: 'update' }),
    toggleSetting () {
      this.isOpen = !this.isOpen
    },
    removeConfig (index) {
      this.formData.data.splice(index, 1)
      this.updateRow()
    },
    addConfig (index) {
      this.formData.data.splice(index + 1, 0, {
        key: '-',
        value: '-',
        description: '-'
      })
      this.updateRow()
    },
    async onUpdate () {
      if (!this.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return
      }
      let data = {
        data: this.dataList
      }
      let [err] = await to(this.updateSetting({ id: this.formData._id, data }))

      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Updete settings error'))
      }

      this.$Message.success(this.$t('Updete settings successful'))
      this.isDirty = false
    },
    swapIndex (index, val) {
      let row = this.dataList.splice(index, 1)
      this.dataList.splice(index + val, 0, row[0])
      this.dataList = _.cloneDeep(this.dataList)
      this.isDirty = true
    },
    updateRow (idx, col, val = null) {
      const d = this.dataList[idx] || {}
      if (!d[col] && d[col] !== '') return
      this.dataList[idx][col] = val
      this.isDirty = true
    }
  }
}
</script>
<style lang="sass" scoped>
  .title
    cursor: pointer
    color: #515a6e
    font-size: 2em
    font-weight: bold
    line-height: 1.5
</style>
